import classNames from 'classnames';
import LinkTo from 'components/linkTo';
import {
  DropdownMenu,
  DropdownMenuWithCategory,
  LanguageSwitcher,
  LogInButton,
} from 'components/navigationBar/components';
import { ENGLISH_LOCALE_IDENTIFIER } from 'constants/i18n';
import { advice, company, resourcesMain } from 'content/navigation/links';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { forwardRef, ReactNode, Ref } from 'react';

import styles from './style.module.scss';

const DesktopNavigationBar = forwardRef(
  (
    {
      menu,
      showLogInButton,
      isHiring,
      isEuLanguageSwitcherActive,
    }: {
      menu: NavigationProductCategory[];
      showLogInButton: boolean;
      isHiring: boolean;
      isEuLanguageSwitcherActive: boolean;
    },

    ref: Ref<HTMLDivElement>
  ) => {
    const router = useRouter();
    const { t } = useTranslation();
    const isLocaleEnDe = router.locale === ENGLISH_LOCALE_IDENTIFIER;

    const getLocalizedLinks = (links: NavigationLink[]) =>
      links.filter(
        ({ locales }) =>
          !(router.locale && locales && !locales.includes(router.locale))
      );

    return (
      <div ref={ref} className={styles.container}>
        <div className={styles.wrapper}>
          <div className={`d-flex ai-center jc-between ${styles.content}`}>
            <LinkTo href="/" aria-label={t('component.navigation.logo.aria')}>
              <div className={styles.logo} />
            </LinkTo>
            <div>
              <div className={styles.linkContainer}>
                <DropdownMenuContainer
                  title={t('component.navigation.product')}
                  hasCategories={menu.length > 1}
                >
                  {menu.length > 1 ? (
                    <DropdownMenuWithCategory menu={menu} />
                  ) : (
                    <DropdownMenu menu={menu[0].products} />
                  )}
                </DropdownMenuContainer>
                {isLocaleEnDe && (
                  <DropdownMenuContainer
                    title={t('component.navigation.advice')}
                  >
                    <DropdownMenu menu={getLocalizedLinks(advice(t))} />
                  </DropdownMenuContainer>
                )}
                <DropdownMenuContainer
                  title={t('component.navigation.company')}
                >
                  <DropdownMenu
                    menu={getLocalizedLinks(company(t))}
                    isHiring={isHiring}
                  />
                </DropdownMenuContainer>

                {getLocalizedLinks(resourcesMain(t)).map(({ name, url }) => (
                  <LinkTo
                    key={name}
                    className={classNames(styles.link, {
                      [styles.linkActive]: router.pathname === url,
                    })}
                    href={url}
                  >
                    {name}
                  </LinkTo>
                ))}
              </div>
            </div>
            <div
              className={`d-flex gap24 ai-center ${styles.rightButtonContainer}`}
            >
              <LanguageSwitcher
                isEuLanguageSwitcherActive={isEuLanguageSwitcherActive}
              />
              {showLogInButton && <LogInButton />}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const DropdownMenuContainer = ({
  title,
  children,
  hasCategories = false,
}: {
  title: string;
  children: ReactNode;
  hasCategories?: boolean;
}) => (
  <div
    className={classNames(styles.productLinkContainer, {
      [styles.productLinkContainerWithCategories]: hasCategories,
    })}
  >
    <button type="button" aria-haspopup="true" className={styles.link}>
      {title}
    </button>
    {children}
  </div>
);

export default DesktopNavigationBar;
