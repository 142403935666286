import { Region } from '@getpopsure/public-models';

type Language = 'en' | 'de' | 'es' | 'fr';

const labels = [
  { id: 'en', label: 'English' },
  { id: 'de', label: 'Deutsch' },
  { id: 'es', label: 'Español' },
  { id: 'fr', label: 'Français' },
];

export const getLabelById = (id: Language) =>
  labels.find((labelObj) => labelObj.id === id)?.label ?? 'English';

export const supportedLanguagesByCountry: Record<Region, Language[]> = {
  de: ['en', 'de'],
  es: ['en', 'es'],
  fr: ['en', 'fr'],
  nl: ['en'],
  it: ['en'],
  be: ['en'],
  se: ['en'],
  mt: ['en'],
  at: ['en'],
  fi: ['en'],
  cy: ['en'],
  gr: ['en'],
  ee: ['en'],
  pt: ['en'],
  lt: ['en'],
  hr: ['en'],
  lv: ['en'],
  mc: ['en'],
};
