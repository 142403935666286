export type Locale =
  | 'en-de'
  | 'de-de'
  | 'en-es'
  | 'es-es'
  | 'en-fr'
  | 'fr-fr'
  | typeof EU_LOCALES[number];

// TODO: update variable names of en-de & de-de to follow en-es & es-es
export const ENGLISH_LOCALE_IDENTIFIER: Locale = 'en-de';
export const GERMAN_LOCALE_IDENTIFIER: Locale = 'de-de';

// Locales supported to signup on app
export const FULLY_SUPPORTED_LOCALES = [
  'en-de',
  'de-de',
  'en-es',
  'es-es',
  'en-fr',
  'fr-fr',
];

export const EU_LOCALES = [
  'en-nl',
  'en-it',
  'en-be',
  'en-se',
  'en-mt',
  'en-at',
  'en-fi',
  'en-cy',
  'en-gr',
  'en-ee',
  'en-pt',
  'en-lt',
  'en-hr',
  'en-lv',
  'en-mc',
] as const;

// TODO: remove redundant list with locales.js
export const LOCALES = [...FULLY_SUPPORTED_LOCALES, ...EU_LOCALES];
