import { COOKIE_KEY } from '@getpopsure/cookie-consent';
import { FilledLinkToWebField } from '@prismicio/types';
import LinkTo from 'components/linkTo';
import LanguageSwitcher from 'components/navigationBar/components/LanguageSwitcher';
import { legals } from 'content/navigation/links';
import useDate from 'hooks/useDate';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { NavigationItemSlice } from 'types.generated';

import FooterMobileApp from './components/FooterMobileApp';
import FooterNavigation from './components/FooterNavigation';
import styles from './style.module.scss';

interface FooterProps {
  condensed?: boolean;
  data: NavigationItemSlice[];
  isEuLanguageSwitcherActive: boolean;
}

const Footer = ({
  condensed,
  data,
  isEuLanguageSwitcherActive,
}: FooterProps) => {
  const { t } = useTranslation();
  const date = useDate();
  const productsMenu = {
    title: data[0].primary.category_title ?? t('component.navigation.product'),
    products: data[0].items
      .map((item) => ({
        id: item.name,
        name: item.name,
        url:
          item.internal_path ||
          (item.external_url as FilledLinkToWebField).url ||
          '',
      }))
      .filter((item) => Boolean(item.name) && Boolean(item.url)),
  };

  const handleDeleteConsentCookie = () => {
    Cookies.remove(COOKIE_KEY, {
      domain:
        process.env.NODE_ENV === 'development'
          ? undefined
          : 'feather-insurance.com',
    });
    window.location.reload();
  };

  const year = date ? date.getFullYear() : '';

  return (
    <div className={`w100 ${styles.container}`}>
      <div className="p-body">
        {!condensed && (
          <FooterNavigation productsMenu={productsMenu as FooterProductsMenu} />
        )}
        <div className={`d-flex mt24 ${styles.languageSwitcherContainer}`}>
          <LanguageSwitcher
            condensed={false}
            isEuLanguageSwitcherActive={isEuLanguageSwitcherActive}
          />

          <FooterMobileApp />
        </div>
        <div className={`mt24 mb16 ${styles.divider}`} />
        <div className={`d-flex ${styles.contentContainer}`}>
          <button
            type="button"
            className={`p-p--small mt8 bg-transparent c-pointer ta-left ${styles.legalLink}`}
            onClick={handleDeleteConsentCookie}
          >
            Cookies
          </button>
          {legals(t).map(({ name, url }) => (
            <LinkTo
              key={url}
              href={url}
              className={`p-p--small mt8 d-inline-block ${styles.legalLink}`}
            >
              {name}
            </LinkTo>
          ))}
        </div>
        <div className="p-p--small tc-grey-500 mt16">
          {t('footer.copyright', {
            year,
          })}
        </div>
      </div>
    </div>
  );
};

export default Footer;
