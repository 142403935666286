import { FilledLinkToWebField } from '@prismicio/types';
import React, { Ref } from 'react';
import { NavigationItemSlice } from 'types.generated';

import Desktop from './desktop';
import Mobile from './mobile';
import styles from './style.module.scss';

interface NavigationBarProps {
  allRefs?: {
    desktopNavRef: Ref<HTMLDivElement>;
    mobileNavRef: Ref<HTMLDivElement>;
  };
  data: NavigationItemSlice[];
  isHiring: boolean;
  showLogInButton: boolean;
  isEuLanguageSwitcherActive: boolean;
}

const NavigationBar = ({
  allRefs,
  data,
  isHiring,
  showLogInButton,
  isEuLanguageSwitcherActive,
}: NavigationBarProps) => {
  const menu = data
    .map((doc) => ({
      title: doc.primary.category_title,
      icon: doc.primary.icon.url,
      products: doc.items
        .map((item) => ({
          name: item.name,
          url:
            item.internal_path ||
            (item.external_url as FilledLinkToWebField).url ||
            '',
        }))
        .filter((item) => Boolean(item.name) && Boolean(item.url)),
    }))
    .filter(
      (category) =>
        Boolean(category.title) &&
        Boolean(category.icon) &&
        category.products.length > 0
    );
  return (
    <>
      <div className={styles.desktop}>
        <Desktop
          ref={allRefs?.desktopNavRef}
          menu={menu as NavigationProductCategory[]}
          isHiring={isHiring}
          showLogInButton={showLogInButton}
          isEuLanguageSwitcherActive={isEuLanguageSwitcherActive}
        />
      </div>
      <div className={styles.mobile}>
        <Mobile
          ref={allRefs?.mobileNavRef}
          menu={menu as NavigationProductCategory[]}
          isHiring={isHiring}
          showLogInButton={showLogInButton}
          isEuLanguageSwitcherActive={isEuLanguageSwitcherActive}
        />
      </div>
    </>
  );
};

export default NavigationBar;
