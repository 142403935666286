import {
  Alpha2CountryCode,
  countryNameFromAlphaCode,
  mapCountryFlag,
  Region,
  regions,
} from '@getpopsure/public-models';

interface Flag {
  id: Region;
  label: string;
  icon: string;
  iconAlt: string;
}

// TODO: add label translation for DE, ES, FR
export const flags = (includeEuRegions: boolean): Flag[] => {
  const regionsForFlag = includeEuRegions
    ? regions
    : (['de', 'es', 'fr'] as Partial<Region>[]);
  return regionsForFlag.map((r) => {
    const codeUpperCased = r.toUpperCase() as Alpha2CountryCode;
    return {
      id: r,
      label: countryNameFromAlphaCode(codeUpperCased),
      icon: mapCountryFlag[codeUpperCased],
      iconAlt: `flag of ${countryNameFromAlphaCode(codeUpperCased)}`,
    };
  });
};
