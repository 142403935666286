const Sentry = require('@sentry/nextjs');
const locales = require('./locales');

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: 'en-de',
    locales,
    localeDetection: false,
    saveMissing: true,
    serializeConfig: false,
    missingKeyHandler: (_lng, _ns, key) => {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`[Translation] Missing key: ${key}`);
      } else {
        Sentry.captureMessage(`[Translation] Missing key: ${key}`, 'error');
      }
    },
  },
};
