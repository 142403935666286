import { GERMAN_LOCALE_IDENTIFIER } from 'constants/i18n';
import { pathWithoutQueryOrAnchor } from 'util/pathSegmentUtil';
import { parseNestedPath } from 'util/prismicPathResolver';

// If route exists & current locale is German & page is not available in German & page is not a preview
// then show not translated wrapper
export const showPageNotTranslatedWrapper = (
  allPaths: Array<string>,
  germanPaths: Array<string>,
  path: string,
  currentLocale: string | undefined
) => {
  if (!currentLocale) return;
  const parsedPath = parseNestedPath(pathWithoutQueryOrAnchor(path));

  return (
    allPaths.includes(parsedPath) &&
    currentLocale === GERMAN_LOCALE_IDENTIFIER &&
    !germanPaths.includes(parsedPath)
  );
};
