import { PrismicPreview } from '@prismicio/next';
import { PRISMIC_REPO_NAME } from 'constants/prismic';
import { ReactNode } from 'react';
import { isProduction } from 'util/isProduction';

const PrismicPreviewWrapper = ({ children }: { children: ReactNode }) =>
  isProduction ? (
    <>{children}</>
  ) : (
    <PrismicPreview repositoryName={PRISMIC_REPO_NAME}>
      {children}
    </PrismicPreview>
  );

export default PrismicPreviewWrapper;
